
        * {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
 
        /*width*/
        body::-webkit-scrollbar {
            width: 12px;
        }

        /*track*/
        body::-webkit-scrollbar-track {
            background: rgb(255, 255, 255);
            border-style: none;
            border-radius: 0px;
        }

        /*thumb*/
        body::-webkit-scrollbar-thumb {
            background: rgb(28, 28, 28);
        }
 
    /* <!-- Page Transition --> */
   
        body .page-transition {
            display: block
        }

        .w-editor .page-transition {
            display: none;
        }

        .no-scroll-transition {
            overflow: hidden;
            position: relative;
        }
  
    /* <!-- Custom Selection Color --> */

        ::selection {
            background: #151515;
            color: white
        }


     
        .swiper-scrollbar {
            background: rgba(255, 255, 255, 0.1);
        }

        .swiper-container-horizontal {
            max-width: none;
        }

        .swiper-container-horizontal>.swiper-scrollbar {
            border-radius: 2px;
            height: 1px;
            width: 100%;
            max-width: 70rem;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .swiper-scrollbar-drag {
            background-color: white;
        }

        .swiper-horizontal>.swiper-scrollbar,
        .swiper-scrollbar.swiper-scrollbar-horizontal {
            height: 2px;
        }

        /* ----------- iPad Pro 12.9" ----------- */
        /* 
      @media only screen 
        and (min-device-width: 1024px) 
        and (max-device-width: 1366px)
        and (-webkit-min-device-pixel-ratio: 2) {

      .home-header_lottie-desktop{
        width: 130%;
       }
    
     .about-header_title{
    	font-size: 10rem;
      margin-bottom: 3rem;
      letter-spacing: -6px;
     }

}
*/
        @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
            .home-header_lottie-desktop {
                width: 130%;
            }
        }
