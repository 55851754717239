* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #fff;
  border-style: none;
  border-radius: 0;
}

body::-webkit-scrollbar-thumb {
  background: #1c1c1c;
}

body .page-transition {
  display: block;
}

.w-editor .page-transition {
  display: none;
}

.no-scroll-transition {
  position: relative;
  overflow: hidden;
}

::selection {
  color: #fff;
  background: #151515;
}

.swiper-scrollbar {
  background: #ffffff1a;
}

.swiper-container-horizontal {
  max-width: none;
}

.swiper-container-horizontal > .swiper-scrollbar {
  height: 1px;
  width: 100%;
  max-width: 70rem;
  border-radius: 2px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-scrollbar-drag {
  background-color: #fff;
}

.swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
  height: 2px;
}

@media only screen and (width >= 1024px) and (height <= 1366px) and (-webkit-device-pixel-ratio >= 1.5) {
  .home-header_lottie-desktop {
    width: 130%;
  }
}

/*# sourceMappingURL=index.79aacafc.css.map */
